/*
*  Description: this file contains a constant variable that defines
*   the code for the sidebar menu
*
*/
/**
 * Alexa documentation: smDown: If true, screens this size and up will be hidden.
 * 
 * smDown: If true, screens this size and down will be hidden.
 * https://material-ui.com/components/hidden/
 */

/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes, { array } from "prop-types";
import { NavLink } from "react-router-dom";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Drawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconExpandLess from '@mui/icons-material/ExpandLess'
import IconExpandMore from '@mui/icons-material/ExpandMore'
import { useLocation } from 'react-router-dom';
import { ourOrange, permissions } from 'assets/jss/incept-sustainability-variables.jsx';
import Plausible from 'plausible-tracker';
const { trackEvent } = Plausible();
// core components

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

//Used for Translations
import LanguageSelect from "components/LanguageSelect/LanguageSelect.js";
import { withTranslation } from 'react-i18next';

const Sidebar = ({ ...props }) => {
	// verifies if routeName is the one active (in browser input)
	function activeRoute(routeName) {
		return window.location.href.indexOf(routeName) > -1 ? true : false;
	}
	const { classes, color, logo, languages, logo2, routes, iscustomManager, showKB, role, renderRedirect,
		defaultLanguage, languageBasedUserCountry, logoutprofile, t } = props;

	let links = (
		<List className={classes.list}>
			{routes.map((prop, key) => {

				if (prop.invisible && !prop.submenu) return null;

				//check if role is allowed, checking permissions
				//if role from route is the different from prop role and role is not both
				if ((role === "Learner Manager" || role === "Custom Learner Manager") && prop.path === "/analytics_overview") return null;
				if ((role === "Manager" || (role !== "Custom Learner Manager" && iscustomManager)) && prop.path === "/analytics_overview_lmanager") return null;
				if (iscustomManager && prop.name === "My actions") return null;
				if (!role.includes(prop.roleD) && prop.roleD != "Both" && role != "Both") return null; //Please review this rule
				if ((prop.path === "/KB" || prop.path === "/knowledgebasesub") && !showKB) return null;


				//check if submenu
				let showSubMenu = false;
				let showArrowDown = false;
				let subMenuClass = (showSubMenu) ? classes.showMenu : classes.hideMenu;
				let propNameNoSpace = prop.name.replace(/\s/g, "");
				let expresion = <IconExpandMore id={`is_arrowDown${propNameNoSpace}`} className={classes.menuArrow} />;
				if ((activeRoute("admin/analytics_") || activeRoute("custom_activity")) 
					&& (prop.name === "Analytics" || prop.name === "Completion log"
					|| prop.name === "Employee feedback" || prop.name === "Ideas and comments")) {
						subMenuClass = classes.showMenu;
				}

				//if kb is active, show submenu
				else if ((activeRoute("admin/KB") || activeRoute("admin/knowledgebasesub")) 
					&& (prop.name === "Knowledge Base" || prop.nameGen === "Knowledge Base Sub")) {
					subMenuClass = classes.showMenuKB;
				}

				let param = (prop.id !== undefined && prop.id!=="") ? "/" + prop.id : "";

				// This is for highlitghting the menu a user clicks on or stands on
				let listItemClasses = classNames({
					[" " + classes[color]]: activeRoute(prop.layout + prop.path + param),
					[" " + subMenuClass]: prop.submenu ? true : false,
				});

				let morePadding = "";
				if (prop.submenu) {
					morePadding = "40px";
				}

				const whiteFontClasses = classNames({
					[" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
				});
				const location = useLocation();
				let navlink = prop.name != "Logout" ? prop.layout + prop.path : location.pathname;
				if (prop.path.includes("knowledgebasesub"))
					navlink = prop.layout + prop.path + "/" + prop.id;
				return (
					<NavLink
						to={navlink}
						className={classes.item}
						onClick={prop.name == "Logout" ? logoutprofile : (activeRoute("admin/KB") || activeRoute("admin/knowledgebasesub")) ? function () { trackEvent("KB " + prop.name); return; } : function () { return; }}
						activeClassName="active"
						style={prop.submenu ? { marginLeft: "10px" } : {}}
						key={key}
					>

						<ListItem style={{
							display: "flex",
							paddingRight: morePadding
						}} button className={classes.itemLink + listItemClasses}>
							{prop.submenu && <ul style={{ listStyleType: "circle", padding: "0 0 0 20px", color: ourOrange }}><li></li></ul>}
							{!prop.submenu && <img className={classes.itemIcon} style={{marginRight: '15px', marginLeft: '5px'}} src={activeRoute(prop.layout + prop.path + param) === false ? prop.icon[0] : prop.icon[1]} alt="nav item icon" />}
							<ListItemText
								style={{
									fontFamily: 'gotham-font-book',
									fontWeight: activeRoute(prop.layout + prop.path + param) ? 'bold' : '',
									color: activeRoute(prop.layout + prop.path + param) ? `${ourOrange}` : 'black' 
								}}
								primary={
									// i18next-extract-disable-next-line
									t(props.rtlActive ? prop.rtlName : prop.name)
								}
								className={classNames(classes.itemText, whiteFontClasses, {
									[classes.itemTextRTL]: props.rtlActive
								})}
								disableTypography={true}
							/>
							{prop.name === "Analytics" && expresion}
							{prop.name === "Knowledge Base" && expresion}
						</ListItem>
					</NavLink>
				);
			})}
		</List>
	);

	//Logo section
	let brand = (
		<div className={classes.logo}>
			<a

				className={classNames(classes.logoLink, {
					[classes.logoLinkRTL]: props.rtlActive
				})}
				target="_blank"
				style={{ textAlign: "center" }}
			>
				<div className={classes.logoImage}>
					<img src={logo} className={classes.img} width="120" />
				</div>
			</a>
		</div>
	);
	//brand 2
	let brand2 = (
		<div className={classes.logoIncept}>
			<a
				href="https://www.inceptsustainability.com/"
				className={classNames(classes.logoLink, {
					[classes.logoLinkRTL]: props.rtlActive
				})}
				style={{ textAlign: "center" }}
				target="_blank"
			>
				<div className={classes.logoImage}>
					<img src={logo2} alt="logo" className={classes.imgBottom} />
				</div>
			</a>
		</div>
	);

	//background
	let backgroundStyle = (
		<div
			className={classes.background}
			style={{ backgroundColor: "white" }}
		/>
	)

	return (
		<div>
			{/* For mobile */}
			<Hidden mdUp implementation="css">
				<Drawer
					variant="temporary"
					anchor={props.rtlActive ? "left" : "right"}
					open={props.open}
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive
						})
					}}
					onClose={props.handleDrawerToggle}
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
					</div>
					{(languages.length > 1) &&
						<div style={{ padding: "0px 35px" }}>
							<LanguageSelect 
							defaultLannguage={defaultLanguage}
							languageBasedUserCountry={languageBasedUserCountry}
							complanf={languages} />
						</div>
					}
					{brand2}
					{/*backgroundStyle*/}
				</Drawer>
			</Hidden>
			{/*For desktop version*/}
			<Hidden mdDown implementation="css">
				<Drawer
					anchor={props.rtlActive ? "right" : "left"}
					variant="permanent"
					open
					classes={{
						paper: classNames(classes.drawerPaper, {
							[classes.drawerPaperRTL]: props.rtlActive
						})
					}}
				>
					{brand}
					<div className={classes.sidebarWrapper}>
						{links}
					</div>
					{(languages.length > 1) &&
						<div style={{ padding: "0px 35px" }}>
							<LanguageSelect 
							defaultLannguage={defaultLanguage}
							languageBasedUserCountry={languageBasedUserCountry}
							complanf={languages} />
						</div>
					}
					{brand2}
					{/*backgroundStyle*/}
				</Drawer>
			</Hidden>
		</div>
	);
};

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	rtlActive: PropTypes.bool,
	handleDrawerToggle: PropTypes.func,
	bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
	logo: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	open: PropTypes.bool
};

export default withTranslation()(withStyles(sidebarStyle)(Sidebar));
