/*!
* Description: this file contains the component for
*  the type of exercise MultiChoiceGroupedText, ex. guide 2, step 3, 
*  					" Exercise: Environmental Sustainability"
* Components: MultiChoiceGroupedText
*/
import React from "react";


// Styles
import withStyles from "@mui/styles/withStyles";
import { ourGreen, grayCape20 } from 'assets/jss/incept-sustainability-variables.jsx';

// Style components
import Container from '@mui/material/Container';
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import checked from 'assets/img/checkedWhiteBg.png';

// Forms
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
	},
	grouping: {
		//border: `2px solid ${ourGreen}`,
		padding: '20px',
	},
	question: {
		textAlign: 'center',
		color: ourGreen,
		fontSize: '24px',
		fontWeight: 'bold',
		marginBottom: '20px'
	},
	labelTitle: {
		fontWeight: 'bolder',
	},
	formGroup: {
		'justify-content': 'center'
	},
	formControlLabelRoot: {
		width: '150px',
		marginLeft: '0',
		marginRight: '50px',
		marginBottom: '20px',
		border: `1px solid #FFF`,
		borderRadius: '0.5rem',
		textAlign: 'center',
		color: '#FFF',

		'&:hover': {
			'@media (hover:hover)' : { //Needed for mobiles not to get stuck on deselecting things
				boxShadow: 'inset 1px 1px 10px 8px '+grayCape20,
			}
		},
		'.Mui-checked ~ &': {
			backgroundColor: grayCape20,
		},
		'&:active': {
			boxShadow: 'inset 1px 1px 10px 18px '+grayCape20
		},
		"@media only screen and (max-width: 960px)": {
			marginRight: '0',
		}
	},
	formControlLabelLabel:{
		width:'100%',
		height: "100%",
		padding: '20px',
		minHeight: '150px',
		borderRadius: '0.5rem',
		'.Mui-checked ~ &':{
			backgroundColor: grayCape20,
		}
	},
	buttonContainer: {
		textAlign: "right",
		marginTop: "20px",
		marginBottom: "60px"
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "24px"
	},
};

/*
* Component that renders the exercise for multi group choice
*/
class MultiChoiceGroupedText extends React.Component {

	constructor(props) {
		super(props);
		const { exercise } = props;
		const { options } = exercise;
		this.state = {
			selectedAnswers: [...new Array(options.length)].map(()=> ({'statement_id':-1,'value':false})),
			explanation: '',
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		}
		for (let i=0;i<options.length;i++) {
			this.state.selectedAnswers[i].statement_id = options[i].statement_id;
		}
		if (typeof this.props.datasav2 !== "undefined") {
			this.state.selectedAnswers = this.props.datasav2["content"];
		}
	}

	handleSubmit(e) {
		e.preventDefault();
		//Commented to allow empty answers
		/*let allFalse = this.state.selectedAnswers.every(function (e) {
			return !e.value;
		});
		if (!allFalse) {*/
			this.setState({ submittedanswer: true });
			this.props.save2("MultiChoiceGroupedText", this.state.selectedAnswers, this.props.inkey, this.props.exercise_id);
		//}
	}

	handleChange(e) {
		const answerIndex = parseInt(e.target.value);
		const setToBool = !this.state.selectedAnswers[answerIndex].value;

		this.setState(state => {
			const sa = state.selectedAnswers;
			sa[answerIndex].value = setToBool;
			//this.props.save2("MultiChoiceGroupedText", sa, this.props.inkey, this.props.exercise_id);
			return { selectedAnswers: sa, submittedanswer: false };
		});
	}

	handleChangeTextArea(e) {
		const answer = e.target.value;
		this.setState({
			explanation: answer
		});
	}

	_renderOptions(options) {
		const { classes } = this.props;
		const st = options.map(
			(option, answerIndex) => {
				const { title, statement } = option;
				const checkbox = <Checkbox color="default" style={{ display: 'none' }} />;

				const label = (
					<React.Fragment>
						<div className={classes.labelTitle}>{title}</div>
						<div className={classes.labelStatement}>{statement}</div>
					</React.Fragment>
				);

				return (
					<FormControlLabel
						classes={{
							root: classes.formControlLabelRoot,
							label: classes.formControlLabelLabel
						}}
						key={answerIndex}
						checked={this.state.selectedAnswers[answerIndex].value}
						control={checkbox}
						label={label}
						onChange={(e) => this.handleChange(e)}
						value={answerIndex}
					/>
				)
			}
		);

		return (
			<FormGroup className={classes.formGroup} row>
				{st}
			</FormGroup>
		);
	}

	_renderTextArea(statement) {
		const { classes, t } = this.props;

		return (
			<React.Fragment>
				<div className={classes.statement}>{statement}</div>
				<TextField 
					className={classes.textarea} 
					variant="outlined" 
					label={t('Write your answer here')}
					margin="normal" 
					placeholder={t('Write your answer here')}
					fullWidth 
					onChange={(e) => this.handleChangeTextArea(e)} 
					value={this.state.explanation} 
				/>
			</React.Fragment>
		);
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { question, options, textarea } = exercise;

		return (
			<div className={classes.root}>
				<div className={classes.grouping}>
					<div className={classes.question}>{question}</div>
					<div>{this._renderOptions(options)}</div>
				</div>
				{ textarea &&
					<div>{this._renderTextArea(textarea.statement)}</div>
				}
				<div className={classes.statusContainer}>
				{this.state.submittedanswer && <p>{t("Thank you! Your feedback has been submitted.")}</p>}
				</div>
				<Container className={classes.buttonContainer}>
					<ActionButton
						size="auto"
						type="submit"
						nomargin={true}
						centered={true}
						onClick={(e) => this.handleSubmit(e)}
					>
						{t('Submit')}
					</ActionButton>
				</Container>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(MultiChoiceGroupedText));