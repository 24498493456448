import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

//form inputs
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';
import {
    isAuthenticated
} from "assets/jss/incept-sustainability-variables.jsx";
import jwt from "jwt-decode";

const languageMap = {
	en: { label: "English", dir: "ltr", active: true },
	da: { label: "Danish", dir: "ltr", active: true },
	fi: { label: "Finnish", dir: "ltr", active: true },
	//es: { label: "Spanish", dir: "ltr", active: false },
	//dk: { label: "Danish", dir: "ltr", active: false },
};

const LanguageSelect = (props) => {
	const [selected, setSelected] = useState(localStorage.getItem("i18nextLng") || "en");
	//const selected = "en";
	const { t } = useTranslation();
	const { complanf } = props;
	var newLangMap = {};

	//get user country
	var decodedtoken = (localStorage.getItem("token")!==null) ? jwt(localStorage.getItem("token")):"";
    const user_country = (decodedtoken.country!==undefined) ? decodedtoken.country:""; //check if user country has a language translation

	let nLang = 0;
	for (let key in languageMap) {
		if (complanf.includes(languageMap[key].label) && 
			(key==="en" 
				|| (key!=="en" && props.languageBasedUserCountry && key.toLowerCase()===user_country.toLowerCase())
				|| (key!=="en" && !props.languageBasedUserCountry))
			) {
			newLangMap[key] = languageMap[key];
			nLang += 1;
		}
	}

	const changeLanguage = (event) => {
		setSelected(event.target.value);
		i18n.changeLanguage(event.target.value);
	}

	if (nLang>1) {
		return (
			<DropDown
				color="magenta"
				value={selected}
				onChange={changeLanguage}
			>
				<MenuItem value="">
					<em>{t('Select language')}</em>
				</MenuItem>
				{Object.keys(newLangMap)?.map(item => (
					<MenuItem value={item} key={item}>
						{newLangMap[item].label}
					</MenuItem>       
				))}
			</DropDown>
		);
	}
	else {
		return <span></span>;
	}
};

export default LanguageSelect;
