/*!
 * Description: this file contains the main base component for
 *  the rendering the main page view of analytics
 *
 * Components: Analytics_New
 */
import React, { useState, useEffect } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import axios from "axios";
import {
    n_comp_pck, ourGreen, ourDarkGreen, ourBlueLight,
    ourBlue, isAuthenticated,get_company_user_info, node_comp_dasboard
} from "assets/jss/incept-sustainability-variables.jsx";

import Dashboard_manager from "views/Dashboard/Dashboard_manager.jsx";
import Dashboard_employee from "views/Dashboard/Dashboard_employee.jsx";
//styles
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { useMediaQuery } from 'react-responsive';

import "react-circular-progressbar/dist/styles.css";
import jwt from "jwt-decode";
//Translate
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import { withTranslation, Trans,useTranslation } from 'react-i18next';

const useStyles = (theme) => ({
    smallButton: ({ color, textColor }) => ({
        backgroundColor: color,
        color: textColor,
        border: "0px",
        borderRadius: "20px",
        fontSize: "14px",
        padding: "5px 30px",
        width: "100%"
    })
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const mobile = useMediaQuery({ query: `(max-width: 1024px)` });
        const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
        return <Component {...props} isMobile={mobile} isTablet={isTablet} />;
    }
}
function Dashboard(props) {
    const { classes, content } = props;
    const [loadingfirst, setloadingfirst] = useState(true);
    const [modulesInfo,setModulesInfo] = useState([]);
    var redlog = false;
    var decodedtoken = "";
    if (!isAuthenticated()) {
        redlog = true;
    }
    else
        decodedtoken = jwt(localStorage.getItem("token"));
    if (redlog)
        props.isExpired();
    /*const [ismanager, setIsManager] = (!redlog) ? React.useState(decodedtoken.role.includes("Manager") || decodedtoken.role === "Admin"
    ? true
    : false):React.useState(false);*/
	const [roleUser, setRoleUser] = (!redlog) ? React.useState(decodedtoken.role):React.useState("");
    const [analytics_manager, setAnalyticsManager] = useState({started_modules: 0,
        p_started_modules:0,
        end_onemodule:0,
        pend_onemodule:0,
        pend_allmodule:0,
        nusers: 0,
        end_one_module:0,
        end_allmodule:0,
        nusers_today:0,
        allusers_today:0,
        end_onemodule_today:0,
        end_allmodule_today:0,
        ideas_submited:0,
        users_ideas:0,
        total_users:0,
        ideas:[]});
    const [analytics_viewer, setanalytics_viewer] = useState({active_module: 0,
        title_module:"",
        title_activity:"",
        answer_activity:0,
        answerp_activity: 0,
        empty_activity:0,
        emptyp_activity:0});
	const { t, i18n } = useTranslation();

    useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		if (loadingfirst) {
            if (roleUser==="Manager" || roleUser==="Custom Manager" || roleUser === "Custom Learner Manager")
			    getncomplpack();
		}
	  }, []);

	//Get ideas from backend
	const getncomplpack = async () => {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
		const useLang = i18n.language;
        //Here we will use the new node.js endpoint
		//let url = n_comp_pck + "dashboard?typeuser=viewer&?wpml_language="+ useLang;
        //Here we will use the new node.js endpoint
        console.log("Request ", node_comp_dasboard);
        let url = node_comp_dasboard;
			
			axios
				.get(url, { headers: { Authorization: authstr } })
				.then((response) => {
					console.log(response);
					var stateCopy = {};
					stateCopy["started_modules"] = response.data.nusers;
					stateCopy["p_started_modules"] = response.data.pnusers;
					stateCopy["end_onemodule"] = response.data.end_onemodule;
					stateCopy["pend_onemodule"] = response.data.pend_onemodule;
                    stateCopy["pend_allmodule"] = response.data.pend_allmodule;
                    stateCopy["nusers_today"] = response.data.nusers_today;
                    stateCopy["nusers"] = response.data.nusers;
                    stateCopy["end_one_module"] = response.data.end_onemodule;
                    stateCopy["end_allmodule"] = response.data.end_allmodule;
                    stateCopy["allusers_today"] = response.data.allusers_today;
                    stateCopy["end_onemodule_today"] = response.data.end_onemodule_today;
                    stateCopy["end_allmodule_today"] = response.data.end_allmodule_today;
					stateCopy["ideas_submited"] = response.data.nideas;
					stateCopy["users_ideas"] = response.data.pideas;
					stateCopy["total_users"] = response.data.allusers;
                    stateCopy["ideas"] = response.data.ideas;
                    setAnalyticsManager(stateCopy);
					var stateCopy2 = {};
					stateCopy2["title_module"] = response.data.infoActivity.module_name;
					stateCopy2["title_activity"] = response.data.infoActivity.title;
					stateCopy2["active_module"] = response.data.infoActivity.nactive;
					stateCopy2["answer_activity"] = response.data.infoActivity.total;
					stateCopy2["answerp_activity"] = response.data.infoActivity.pactive;
					stateCopy2["empty_activity"] = response.data.infoActivity.empty;
					stateCopy2["emptyp_activity"] = response.data.infoActivity.pempty;
					setanalytics_viewer(stateCopy2);
                    setModulesInfo(response.data.module_completion);
                    setloadingfirst(false);
				})
				.catch((err) => {
					//this.error_ocurred("Error API "+n_comp_pck+" "+err);
                    console.log("Error ",err);
				});
	}

    if (roleUser==="Manager" || roleUser==="Custom Manager") {
		return (
			<LoadingOverlay
					active={loadingfirst}
					spinner={<BounceLoader />}
				>
			<div className={classes.containerAnalytics}>
				<Dashboard_manager
				data={analytics_manager}
				analytics_viewer={analytics_viewer}
				modulesInfo={modulesInfo}
				username={t('Welcome username',{username:props.databackend.username, defaultValue:"Welcome, {{username}}"})}
				t={t}
				/>
			</div>
			</LoadingOverlay>
		)
    }
    else {
        return (
            <Dashboard_employee 
            databackend={props.databackend}
            isExpired={props.isexpired}
			role={roleUser}
            />
        )
    }

}
export default
    withMyHook(withStyles(
        (theme) => ({
            ...analyticsStyle(theme),
            ...useStyles(theme),
        }),
        { withTheme: true },
    )(Dashboard));

