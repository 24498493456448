// Incept sustainability own variables
import clientConfig from 'client-config.js';
import jwt from "jwt-decode";
import { useState, useEffect } from 'react';

const siteUrl = clientConfig.siteUrl;
const nodesiteUrl = clientConfig.nodesiteUrl;

const standardRadious = "16px";
const borderRadiousButtons = "11px";
const ourDarkGreen    = "#125A40";
//const ourGreen    = "#add095";
const ourBlue = "#009df0";
const ourYellow     = "#ffe682";
const ourOrange   = "#ff8855";
const ourGreen = "#ACD092";
const ourLightGreen = "#DEECD5";
const ourBoxGreen="#e6f1df";
const ourBackgroGreen ="#ADD0954D";
const ourMediumGreen = "#ADD095";
const ourFeedbackGreen= "#ADD19499";
const ourDarkGreen60 = "rgba(18,90,64,0.6)";
const ourDarkGreen40 = "rgba(18,90,64,0.4)";
const ourGreen40 = "rgba(173,208,149,0.4)";
const ourGreen20 = "rgba(173,208,149,0.2)";
const ourGreen60 = "rgba(173,208,149,0.6)";
//const ourBlue60 = "rgba(0,157,240,0.6)";
const ourBlue60 = "#66C4F6";
const ourBlueLight = "#CCEBFD";
const ourOrange80 = "rgba(255,136,85,0.8)";
const ourOrange60 = "rgba(255,136,85,0.6)";
const ourOrange25 = "rgba(255,136,85,0.25)";
const grayCape20 = "rgba(203,203,203,0.2)";
const grayCape40 = "rgba(203,203,203,0.4)";
const get_company_user_info         = siteUrl+"wp-json/incept-api/v1/user/select=";
const get_jsons         = siteUrl+"wp-json/incept-api/v1/modules/";
const reset_password = siteUrl+"wp-json/incept-api/v1/user/password";
const user_general_info = siteUrl+"wp-json/user/nfpr/22?gdt=";
const user_info         = siteUrl+"wp-json/incept-api/v1/user";
const user_info_up      = siteUrl+"wp-json/user/nfpr_up/22?gdt=";
const help_email = siteUrl+"wp-json/incept-api/v1/user/help";
const get_onb_email = siteUrl+"wp-json/incept-api/v1/user/notification";
const logout_wp = siteUrl+"wp-json/incept-api/v1/user/logout";
const mgfile_st = siteUrl+"wp-json/incept-api/v1/user/uploadfl";
const reminder_email = siteUrl+"wp-json/incept-api/v1/user/reminder";
const save_data         = siteUrl+"wp-json/incept-api/v1/modules/";
const is_g_comp         = siteUrl+"wp-json/incept-api/v1/user/modulestatus=";
const n_comp_pck        = siteUrl+"wp-json/incept-api/v1/company/select=";
const n_comp_cust        = siteUrl+"wp-json/incept-api/v1/company/exercise/";
const n_comp_answer_export        = siteUrl+"wp-json/incept-api/v1/company/exportanswer/";
const n_comp_nly_3      = siteUrl+"wp-json/incept-api/v1/company/ideas/select=";
const n_comp_reg      = siteUrl+"wp-json/incept-api/v1/company/create=";
const n_comp_user_reg      = siteUrl+"wp-json/incept-api/v1/company/user/create=";
const save_fav_id       = siteUrl+"wp-json/incept-api/v1/company/ideas/select=";
const save_label        = siteUrl+"wp-json/incept-api/v1/company/ideas/select=";
const actions_post        = siteUrl+"wp-json/incept-api/v1/company/actions/select=";
const n_dep_info = siteUrl+"wp-json/incept-api/v1/company/department/select=";
const n_comp_info = siteUrl+"wp-json/incept-api/v1/company/registration/select=";
const get_answers        = siteUrl+"wp-json/incept-api/v1/modules/";
const urllog        = siteUrl+"wp-json/incept-api/v1/log";
const urllabel        = siteUrl+"wp-json/incept-api/v1/label/";
const stagesite = "stageapp.inceptsustainability.com";

//node.js endpoints
const node_comp_dasboard        = nodesiteUrl+"companies/dashboard";
const node_ideas_page = nodesiteUrl+"companies/ideas";
const node_dashboard = nodesiteUrl+"users/dashboard/employee?select=";
const node_elearningpage = nodesiteUrl+"users/module-status";

var isActive = true;

var incept_roles = ["Manager","Employee","Custom Manager","Learner Manager","Custom Learner Manager"];


const permissions = new Map([
	["Manager", {"Analytics":true, "WriteModule":false}],
	["Employee", {"Analytics":false, "WriteModule":true}],
	["Custom Manager", {"Analytics":true, "WriteModule":false}],
	["Learner Manager", {"Analytics":true, "WriteModule":true}],
	["Custom Learner Manager", {"Analytics":true, "WriteModule":true}],
  ]);

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
	  width,
	  height
	};
}
  
export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
	useEffect(() => {
	  function handleResize() {
		setWindowDimensions(getWindowDimensions());
	  }
  
	  window.addEventListener('resize', handleResize);
	  return () => window.removeEventListener('resize', handleResize);
	}, []);
  
	return windowDimensions;
}

function isAuthenticated() {
	if (!localStorage.getItem("token")) {
		return false;
	}
		
	const decodedtoken = jwt(localStorage.getItem("token"));
	try {
	  if (Date.now() >= decodedtoken.exp * 1000) {
		//Logout
		localStorage.removeItem('token');
		localStorage.removeItem('i18nextLng');
      	//localStorage.removeItem('userName');
		return false;
	  }
	} catch (err) {
	  return false;
	}
	return true;
}

const exerciseStyle   = {
	color : "#fff",
	bgColor : ourBlue,
	title : {
		fontSize : '22px'
	}
};

export { 
	standardRadious,
	useWindowDimensions,
	ourDarkGreen,
	ourDarkGreen60,
	ourDarkGreen40,
	ourBackgroGreen,
	ourGreen,
	ourGreen20,
	ourFeedbackGreen,
	ourLightGreen,
	ourBoxGreen,
	ourMediumGreen,
	ourOrange80,
	ourOrange60,
	ourOrange25,
	ourGreen40,
	ourGreen60,
	ourBlue,
	ourBlue60,
	ourBlueLight,
	ourYellow,
	ourOrange,
	grayCape20,
	grayCape40,
	exerciseStyle,
	user_general_info,
	reset_password,
	user_info,
	user_info_up,
	logout_wp,
	n_dep_info,
	n_comp_info,
	help_email,
	get_onb_email,
	mgfile_st,
    save_data,
	reminder_email,
	isActive,
	is_g_comp,
	node_dashboard,
	n_comp_pck,
	actions_post,
	n_comp_nly_3,
	n_comp_reg,
	n_comp_user_reg,
	save_fav_id,
	save_label,
	get_jsons,
	n_comp_cust,
	n_comp_answer_export,
	get_company_user_info,
	get_answers,
	urllog,
	urllabel,
	isAuthenticated,
	borderRadiousButtons,
	node_comp_dasboard,
	node_ideas_page,
	node_elearningpage,
	permissions,
	stagesite
}