import {
  ourGreen,
  ourLightGreen,
  ourOrange,
  ourBlue,
} from "assets/jss/incept-sustainability-variables.jsx";

const analyticsStyle = (theme) => ({
  titleHeader: {
    lineHeight: "3",
    padding: "0.4rem 1rem 0 1rem",
    margin: "0",
    fontWeight: "400",
    "@media only screen and (max-width: 960px)": {
      lineHeight: "1.5",
      margin: "50px 0",
    },
  },
  containerAnalytics: {
    paddingRight: "25px",
    paddingLeft: "40px",
    marginRight: "auto",
    marginLeft: "auto",
	marginBottom: "20px"
  },
  standardTable: {
    "& th,& td": {
      border: "0",
    },
    "& th:first-child": {
      paddingLeft: 0,
    },
  },
  standardTableNoPadding: {
    "& th,& td": {
      border: "0",
      paddingLeft: "0",
    },
    "& th": {
      paddingRight: "0",
    },
    "& td": {
      paddingLeft: "0",
    },
    "& th:first-child": {
      paddingLeft: 0,
    },
  },
  standardTableNoPaddingL: {
    "& th,& td": {
      border: "0",
      paddingLeft: "0",
    },
    "& td": {
      paddingLeft: "0",
    },
    "& th:first-child": {
      paddingLeft: 0,
    },
  },
  noLeftPadding: {
    paddingLeft: "0 !important",
  },
  noRightPadding: {
    paddingRight: "0 !important",
  },
  completionRow: {
    borderBottom: "none",
  },
  completionModuleRow: {
    borderBottom: "none",
    textAlign: "center",
    lineHeight: "1px",
  },
  completionRowTh: {
    borderBottom: "none",
    paddingLeft: "0px",
  },
  completionRowTh2: {
    borderBottom: "none",
    paddingLeft: "0px",
    paddingRight: "0",
  },
  completionSpan: {
    fontSize: "0.875rem",
    textAlign: "left",
    fontFamily: "gotham-font-book",
    fontWeight: "400",
  },
  completionSpanColor: {
    fontSize: "0.875rem",
    textAlign: "left",
    fontFamily: "gotham-font-book",
    fontWeight: "400",
    color: "rgb(0, 157, 240)",
  },
  completionRowLast: {
    borderBottom: "none",
    paddingLeft: "0 !important",
  },
  actionGridItem: {
    padding: "0 !important",
  },
  tableContainer: {
    overflowX: "hidden",
  },
  moduleDiv: {
    textAlign: "left",
    backgroundColor: "#F9F9F7",
    padding: "8px",
    paddingRight: "10px",
    marginRight: "10px",
    fontSize: "18px",
    fontFamily: "calibri",
  },
  ModuleDivMobile: {
    fontFamily: "calibri",
    fontSize: "16px",
    textAlign: "left",
    marginLeft: "14px",
    paddingRight: "9px",
    backgroundColor: "#F9F9F7",
    padding: "4px",
  },
  columnCardsPadding: {
    padding: "0 15px 30px 0 !important",
    "@media only screen and (max-width: 960px)": {
      padding: "0 15px 30px 0 !important",
    },
  },
  paddingFilter: {
    paddingLeft: "2rem !important",
    paddingRight: "0 !important",
    "@media only screen and (max-width: 960px)": {
      paddingLeft: "0 !important",
    },
  },
  paddingFilter2: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    "@media only screen and (max-width: 960px)": {
      paddingLeft: "0 !important",
    },
  },
  Link_style: {
    marginLeft: "auto",
    color: "#ff8855",
  },
  Link_color: {
    color: "#ff8855",
  },
  doubleLineDD: {
    "& .MuiSelect-select.MuiSelect-select": {
      width: "140px",
      whiteSpace: "initial",
    },
  },
  doubleLineDD2: {
    "& .MuiSelect-select.MuiSelect-select": {
      marginRight: "20px",
    },
  },
  datepicker: {
    "&  div:nth-child(2)": {
      //width: 20,
    },
  },
  datepickerinput: {
    marginLeft: "10px",
    marginRight: "10px",
    border: "none",
    color: "#009df0",
    fontWeight: "700",
    width: "95px",
  },
  datepickercl: {
	marginLeft: "10px",
  },
  formTitle: {
	fontWeight: "200",
	fontSize: "0.9em"
	}
});

export default analyticsStyle;
